import React from "react";
import styles from "./Inara.module.scss";

import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
//import { useMediaLayout } from "use-media";

import {
  logoEclipse,
  inaraLogo,
  inaraSubtitle,
  modelBig,
  featureImage1,
  featureImage2,
  featureImage3,
  videoPlaceholder
} from "../../assets/product-pages/inara";

import productVideoPlaceholder from "../../assets/product-pages/shibui/product_video_placeholder.png";

export default function PPInara() {
  //const isDesktop = useMediaLayout({ minWidth: 992 }, true);
  return (
    <div className={`${styles.page_bg} row`}>
      <Controller>
        <Scene
          duration="1000%"
          pin
          pinSettings={{ pushFollowers: false }}
          triggerHook="onLeave"
        >
          {progress => (
            <section className={`${styles.pp_section} ${styles.section_bg}`}>
              <Timeline
                target={<div className={styles.bg} />}
                paused
                totalProgress={progress}
              >
                <Tween to={{ scale: 1.3 }} />
              </Timeline>
            </section>
          )}
        </Scene>
        <Scene duration="350%" pin triggerHook="onLeave">
          {progress => (
            <section
              className={`${styles.pp_section} ${
                styles.section1
              } row no-gutters`}
            >
              <div className="col-lg-6 h-100">
                <Timeline
                  wrapper={<div className={styles.lhs} />}
                  paused
                  totalProgress={progress}
                >
                  <Tween
                    duration={1}
                    from={{ opacity: 1, yPercent: 0 }}
                    to={{ opacity: 0, yPercent: -10 }}
                  >
                    <div className={styles.logo_container}>
                      <Tween
                        to={{ rotation: 360 }}
                        duration={300}
                        ease="Linear.easeNone"
                      >
                        <img src={logoEclipse} alt="" className="img-fluid" />
                      </Tween>
                      <div className={styles.logo}>
                        <Tween
                          staggerTo={{ opacity: 1, yPercent: 0 }}
                          staggerFrom={{ opacity: 0, yPercent: 30 }}
                          stagger={0.5}
                        >
                          <img src={inaraLogo} alt="Inara" />
                          <img src={inaraSubtitle} alt="A Ray of Light" />
                        </Tween>
                      </div>
                    </div>
                  </Tween>
                  <Timeline
                    duration={3}
                    target={
                      <div className={styles.product_description}>
                        <p>
                          In our humble opinion, the Inara deserves a spot in
                          every woman's wardrobe. It's incredibly versatile and
                          can be pulled off in various avatars.
                        </p>
                        <p>
                          For the day when you feel you have nothing to wear,
                          this is your ray of light. The Inara makes you look
                          good in anything.
                        </p>
                      </div>
                    }
                  >
                    <Tween
                      duration={1}
                      from={{ opacity: 0, yPercent: 10 }}
                      to={{ opacity: 1, yPercent: 0 }}
                    />
                    <Tween duration={1.5} />
                    <Tween duration={0.5} to={{ opacity: 0, yPercent: -10 }} />
                  </Timeline>
                </Timeline>
              </div>
              <div className="col-lg-6 h-100">
                <Timeline
                  wrapper={<div className={styles.rhs} />}
                  paused
                  totalProgress={progress}
                >
                  <Timeline
                    position={0}
                    duration={4}
                    target={<img src={modelBig} alt="" />}
                  >
                    <Tween
                      duration={1}
                      from={{ scale: 1, yPercent: 20 }}
                      to={{ scale: 0.5, yPercent: 0 }}
                    />
                    <Tween duration={2.5} />
                    <Tween duration={0.5} to={{ opacity: 0, yPercent: -10 }} />
                  </Timeline>
                </Timeline>
              </div>
            </section>
          )}
        </Scene>
        <Scene duration="300%" pin triggerHook="onLeave">
          {progress => (
            <section className={`${styles.pp_section} ${styles.section2}`}>
              <div className="row no-gutters h-100 align-items-center">
                <div className="col-12">
                  <div className="row no-gutters">
                    <Timeline paused totalProgress={progress}>
                      <Tween
                        from={{ yPercent: 20, opacity: 0 }}
                        to={{ yPercent: 0, opacity: 1 }}
                      >
                        <div className="col-lg-4">
                          <div className={styles.featureItem}>
                            <img src={featureImage1} alt="" />
                            <p>
                              100% Cotton Breathable, Quick drying &amp; Soft
                            </p>
                          </div>
                        </div>
                      </Tween>
                      <Tween
                        from={{ yPercent: 20, opacity: 0 }}
                        to={{ yPercent: 0, opacity: 1 }}
                      >
                        <div className="col-lg-4">
                          <div className={styles.featureItem}>
                            <img src={featureImage2} alt="" />
                            <p>
                              A slimming-formal drape with a hint of
                              sophistication to make you look sharp &amp; classy
                            </p>
                          </div>
                        </div>
                      </Tween>
                      <Tween
                        from={{ yPercent: 20, opacity: 0 }}
                        to={{ yPercent: 0, opacity: 1 }}
                      >
                        <div className="col-lg-4">
                          <div className={styles.featureItem}>
                            <img src={featureImage3} alt="" />
                            <p>Fits well on all body types</p>
                          </div>
                        </div>
                      </Tween>
                    </Timeline>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Scene>
        <Scene duration="50%" triggerHook="onCenter">
          {progress => (
            <section className={`${styles.pp_section} ${styles.section3}`}>
              <div className="container h-100">
                <div className="h-100 row align-content-center justify-content-center">
                  <Timeline duration={4} paused totalProgress={progress}>
                    <Tween
                      from={{ opacity: 0, y: 50 }}
                      to={{ opacity: 1, y: 0 }}
                    >
                      <div className="col-12 text-center">
                        <h3>5 Unique Ways to Style the Inara</h3>
                      </div>
                    </Tween>
                    <Tween
                      from={{ opacity: 0, y: 50 }}
                      to={{ opacity: 1, y: 0 }}
                    >
                      <div className="col-lg-8 text-center">
                        {
                          // TODO: Video Player
                        }
                        <img
                          src={videoPlaceholder}
                          alt="Video Placeholder"
                          className="img-fluid rounded"
                        />
                      </div>
                    </Tween>
                    <Tween duration={2} />
                  </Timeline>
                </div>
              </div>
            </section>
          )}
        </Scene>
        <Scene duration="100%" triggerHook="onEnter">
          {progress => (
            <section className={`${styles.product_section} w-100`}>
              <div className="row no-gutters">
                <div className="col-lg-auto">
                  <img
                    src={productVideoPlaceholder}
                    alt="Product Video"
                    className={styles.product_plaque_image}
                  />
                </div>
                <div className={`col-lg text-white ${styles.product_plaque}`}>
                  <Timeline
                    paused
                    totalProgress={progress}
                    wrapper={
                      <div className="d-flex h-100 flex-column text-center align-items-center justify-content-center" />
                    }
                  >
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
                      <h2 className={styles.title}>The Shibui</h2>
                      <p className={styles.description}>
                        This shirt is available in 5 sizes, and 3 more sizes are
                        up for pre-ordering. Find your right size now!
                      </p>
                      <div className="mt-1">
                        <a
                          className="btn btn-outline-light mr-3 btn-ucase"
                          href="/find-size"
                        >
                          Find my size
                        </a>
                        <a
                          className="btn btn-primary btn-ucase btn-glow"
                          href="/buy"
                        >
                          Buy Now
                        </a>
                      </div>
                    </Tween>
                    <Tween duration={1} />
                  </Timeline>
                </div>
              </div>
            </section>
          )}
        </Scene>
      </Controller>
    </div>
  );
}
