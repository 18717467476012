import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PPClassique from "./pages/Classique/Classique";
import PPZen from "./pages/Zen/Zen";
import PPShibui from "./pages/Shibui/Shibui";
import PPInara from "./pages/Inara/Inara";
// import Header from './components/Header/Header';

function App() {
  return (
    <div className="page">
      <div className="container-fluid">
        <Router>
          <Route path="/inara" component={PPInara} />
          <Route path="/shibui" component={PPShibui} />
          <Route path="/zen" component={PPZen} />
          <Route path="/" exact component={PPClassique} />
        </Router>
      </div>
    </div>
  );
}

export default App;
