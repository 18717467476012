import React, { PureComponent } from "react";
import styles from "./Classique.module.scss";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import modelImage from "../../assets/product-pages/classique_masked_web.png";
import blockImage1 from "../../assets/product-pages/img-block-1.png";
import blockImage2 from "../../assets/product-pages/img-block-2.png";
import blockImage3 from "../../assets/product-pages/img-block-3.png";
import videoPlaceholder from "../../assets/product-pages/video_placeholder.png";
import CottonIcon from "../../assets/product-pages/cotton_icon.svg";
import productVideoPlaceholder from "../../assets/product-pages/product_video_placeholder.png";

export class PPClassique extends PureComponent {
  render() {
    return (
      <div className={`${styles.page_bg} row`}>
        <Controller>
          <Scene
            duration="300%"
            pin
            triggerHook="onLeave"
            pinSettings={{ pushFollowers: false }}
          >
            {progress => (
              <section className={`${styles.pp_section} ${styles.section1}`}>
                <Timeline
                  paused
                  totalProgress={progress}
                  target={<div className={styles.content_bar} />}
                >
                  <Tween duration={0.1} />
                  <Tween from={{ scaleY: 1 }} to={{ scaleY: 6 }} />
                  <Tween duration={1} />
                </Timeline>
                <div className={styles.lhs}>
                  <Timeline
                    paused
                    totalProgress={progress}
                    target={
                      <img
                        src={modelImage}
                        className={styles.model}
                        alt="Classique"
                      />
                    }
                  >
                    <Tween from={{ scale: 1.5 }} to={{ scale: 1 }} />
                    <Tween duration={2} />
                    <Tween from={{ opacity: 1 }} to={{ opacity: 0 }} />
                  </Timeline>
                </div>
                <div className={styles.rhs}>
                  <Timeline paused totalProgress={progress}>
                    <Tween
                      duration={0.8}
                      from={{ yPercent: 0, opacity: 1 }}
                      to={{ yPercent: -50, opacity: 0 }}
                    >
                      <div className={styles.product_brand}>
                        <h2>
                          <span>The</span> Classique
                        </h2>
                        <span className={styles.subtitle}>
                          Class always tells
                        </span>
                      </div>
                      <div className={styles.ellipse} />
                    </Tween>

                    <Timeline
                      wrapper={<div className={styles.product_description} />}
                    >
                      <Tween
                        from={{ opacity: 0, yPercent: 50 }}
                        to={{ opacity: 1, yPercent: 0 }}
                      >
                        <h4 className={styles.title}>
                          The Classique is our quintessential, no non-sense
                          black shirt.
                        </h4>
                        <hr className="w-50" />
                      </Tween>
                      <Tween
                        from={{ opacity: 0, yPercent: 50 }}
                        to={{ opacity: 1, yPercent: 0 }}
                      >
                        <p className={styles.bodyText}>
                          The <strong>Egyptian Giza Cotton</strong> helps gives
                          you a floaty feeling as the mercury rises in summer.
                          The fabric dyes beautifully, ensuring your{" "}
                          <strong>black shirt stays a black shirt.</strong>
                        </p>
                        <div
                          className={`${styles.carbon} media text-left mx-auto`}
                        >
                          <img
                            src={CottonIcon}
                            className="align-self-center mr-2"
                            alt="Cotton"
                          />
                          <div className="media-body">
                            <h5>Carbon Finish</h5>
                            <p className="mb-0">
                              Adds a pleasant softness & a warm feel to the
                              fabric
                            </p>
                          </div>
                        </div>
                      </Tween>
                    </Timeline>
                    <Tween duration={2} />
                  </Timeline>
                </div>
              </section>
            )}
          </Scene>
          <section style={{ height: "200vh", width: "100%" }} />
          <Scene duration="40%" triggerHook="onEnter">
            {progress => (
              <section className={`${styles.pp_section} ${styles.section2}`}>
                <div className="row no-gutters h-100 overflow-hidden">
                  <div className="col-md-8 h-75 h-md-100">
                    <div className={styles.img_container}>
                      <h3>The shirt too good to gift</h3>
                      <Timeline
                        paused
                        totalProgress={progress}
                        target={<img src={blockImage1} alt="Block 1" />}
                      >
                        <Tween from={{ scale: 1.2 }} to={{ scale: 1 }} />
                      </Timeline>
                    </div>
                  </div>
                  <div className="col-md-4 h-25 h-md-100">
                    <div className="row no-gutters h-100 overflow-hidden">
                      <div className="col-6 col-md-12 h-md-50">
                        <div className={styles.img_container}>
                          <img src={blockImage2} alt="Block 2" />
                        </div>
                      </div>
                      <div className="col-6 col-md-12 h-md-50">
                        <div className={styles.img_container}>
                          <img src={blockImage3} alt="Block 3" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Scene>
          <Scene duration="100%" triggerHook="onCenter">
            {progress => (
              <section className={`${styles.pp_section} ${styles.section3}`}>
                <div className="container-fluid h-100">
                  <div className="h-100 row align-content-center justify-content-center">
                    <Timeline paused totalProgress={progress}>
                      <Tween
                        from={{ opacity: 0, y: 50 }}
                        to={{ opacity: 1, y: 0 }}
                      >
                        <div className="col-12 text-center">
                          <h3>Class always tells</h3>
                        </div>
                      </Tween>
                      <Tween
                        from={{ opacity: 0, y: 50 }}
                        to={{ opacity: 1, y: 0 }}
                      >
                        <div className="col-lg-8 text-center">
                          {
                            // TODO: Video Player
                          }
                          <img
                            src={videoPlaceholder}
                            alt="Video Placeholder"
                            className="img-fluid rounded"
                          />
                        </div>
                      </Tween>
                      <Tween duration={2} />
                    </Timeline>
                  </div>
                </div>
              </section>
            )}
          </Scene>
          <Scene duration="100%" triggerHook="onEnter">
            {progress => (
              <section className={`${styles.section4} w-100`}>
                <div className="row no-gutters">
                  <div className="col-lg-auto">
                    <img
                      src={productVideoPlaceholder}
                      alt="Product Video"
                      className={styles.product_plaque_image}
                    />
                  </div>
                  <div className={`col-lg text-white ${styles.product_plaque}`}>
                    <Timeline
                      paused
                      totalProgress={progress}
                      wrapper={
                        <div className="d-flex h-100 flex-column text-center align-items-center justify-content-center" />
                      }
                    >
                      <Tween
                        staggerFrom={{ opacity: 0 }}
                        staggerTo={{ opacity: 1 }}
                        stagger={0.1}
                      >
                        <h2 className={styles.title}>The Classique</h2>
                        <p className={styles.description}>
                          This shirt is available in 5 sizes, and 3 more sizes
                          are up for pre-ordering. Find your right size now!
                        </p>
                        <div className="mt-1">
                          <a
                            className="btn btn-outline-light mr-3 btn-ucase"
                            href="/find-size"
                          >
                            Find my size
                          </a>
                          <a
                            className="btn btn-primary btn-ucase btn-glow"
                            href="/buy"
                          >
                            Buy Now
                          </a>
                        </div>
                      </Tween>
                      <Tween duration={1} />
                    </Timeline>
                  </div>
                </div>
              </section>
            )}
          </Scene>
        </Controller>
      </div>
    );
  }
}

export default PPClassique;
