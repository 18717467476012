import React, { PureComponent } from "react";
import styles from "./Zen.module.scss";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import watercolorBlot from "../../assets/product-pages/zen/watercolor_blot.png";
import zenLogo from "../../assets/product-pages/zen/zen_logo.svg";
import zenModel from "../../assets/product-pages/zen/model.png";
import videoPlaceholder from "../../assets/product-pages/video_placeholder.png";
import productVideoPlaceholder from "../../assets/product-pages/product_video_placeholder.png";
import featureEllipse1 from "../../assets/product-pages/zen/feature_ellipse_1.png";
import featureEllipse2 from "../../assets/product-pages/zen/feature_ellipse_2.png";
import featureEllipse3 from "../../assets/product-pages/zen/feature_ellipse_3.png";

export class PPZen extends PureComponent {
  render() {
    return (
      <div className={`${styles.page_bg} row`}>
        <Controller>
          <Scene
            duration="600%"
            pin
            triggerHook="onLeave"
            pinSettings={{ pushFollowers: false }}
          >
            <Timeline
              duration={1}
              target={
                <section
                  className={`${styles.pp_section} ${styles.section_bg}`}
                />
              }
            >
              <Tween
                delay={0.4}
                from={{ backgroundPosition: "0px 0px" }}
                to={{ backgroundPosition: "0px -100px" }}
              />
            </Timeline>
          </Scene>

          <Scene duration="100%" pin triggerHook="onLeave">
            {progress => (
              <section className={`${styles.pp_section} ${styles.section1}`}>
                <Timeline
                  paused
                  duration={1}
                  totalProgress={progress}
                  target={<div className={styles.shirt_video} />}
                >
                  <Tween
                    position={2}
                    delay={0.9}
                    from={{ opacity: 1, yPercent: 0 }}
                    to={{ opacity: 0, yPercent: -10 }}
                  />
                </Timeline>
                <div className={styles.logo_rhs}>
                  <Timeline
                    wrapper={<div />}
                    paused
                    duration={1}
                    totalProgress={progress}
                  >
                    <Tween
                      position={1}
                      from={{ yPercent: 0, opacity: 1 }}
                      to={{ yPercent: -10, opacity: 0 }}
                    >
                      <img src={watercolorBlot} alt="" />
                    </Tween>
                    <Tween
                      position={1}
                      from={{ yPercent: 0, opacity: 1 }}
                      to={{ yPercent: -40, opacity: 0 }}
                    >
                      <img src={zenLogo} alt="" className={styles.zenLogo} />
                    </Tween>
                  </Timeline>
                </div>
              </section>
            )}
          </Scene>
          <Scene duration="100%" pin triggerHook="onLeave">
            {progress => (
              <section
                className={`${styles.pp_section} ${
                  styles.section2
                } overflow-hidden`}
              >
                <div className="container-fluid">
                  <div className="row no-gutters vh-100 overflow-hidden align-items-center">
                    <div
                      className={`${
                        styles.lhs
                      } col-lg-6 vh-100 overflow-hidden text-center`}
                    >
                      <Timeline
                        target={<img src={zenModel} alt="" />}
                        paused
                        duration={1}
                        totalProgress={progress}
                      >
                        <Tween
                          position={0}
                          from={{ scale: 1, yPercent: 0 }}
                          to={{ scale: 0.9, yPercent: -5 }}
                        />
                        <Tween
                          position={1}
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                        />
                      </Timeline>
                    </div>
                    <div
                      className={`${
                        styles.rhs
                      } col-lg-6 text-center text-white`}
                    >
                      <Timeline
                        target={
                          <h5 className="mr-5">
                            The Zen shirt gives you peace of mind & allows you
                            to concentrate on your meetings or work. You can
                            tuck in or tuck it out. It’s a shirt which shifts
                            the focus on you.
                          </h5>
                        }
                        paused
                        duration={1}
                        totalProgress={progress}
                      >
                        <Tween
                          position={1}
                          from={{ opacity: 1 }}
                          to={{ opacity: 0 }}
                        />
                      </Timeline>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Scene>

          <Scene duration="80%" triggerHook="onEnter">
            {progress => (
              <section className={`${styles.pp_section} ${styles.section3}`}>
                <div className="container-fluid">
                  <div className="row">
                    <div className={`col vh-100 ${styles.features_container}`}>
                      <Timeline duration={1} paused totalProgress={progress}>
                        <Tween
                          duration={0.5}
                          stagger={0.1}
                          staggerFrom={{ opacity: 0, yPercent: 30 }}
                          staggerTo={{ opacity: 1, yPercent: 0 }}
                        >
                          <div className={styles.feature_card}>
                            <img
                              src={featureEllipse1}
                              alt="A Perfect Linen Cotton Blend"
                              className="img-fluid"
                            />
                            <p>A Perfect Linen Cotton Blend</p>
                          </div>
                          <div className={styles.feature_card}>
                            <img
                              src={featureEllipse2}
                              alt="Anti-Crush"
                              className="img-fluid"
                            />
                            <p>Anti-Crush</p>
                          </div>
                          <div className={styles.feature_card}>
                            <img
                              src={featureEllipse3}
                              alt="Mandarin Collar"
                              className="img-fluid"
                            />
                            <p>Mandarin Collar</p>
                          </div>
                        </Tween>
                        <Tween duration={0.5} />
                      </Timeline>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Scene>
          <Scene duration="100%" triggerHook="onCenter">
            {progress => (
              <section className={`${styles.pp_section} ${styles.section4}`}>
                <div className="container h-100">
                  <div className="h-100 row align-content-center justify-content-center">
                    <Timeline paused totalProgress={progress}>
                      <Tween
                        from={{ opacity: 0, y: 50 }}
                        to={{ opacity: 1, y: 0 }}
                      >
                        <div className="col-12 text-center">
                          <h3>The most versatile shirt in your wardrobe</h3>
                        </div>
                      </Tween>
                      <Tween
                        from={{ opacity: 0, y: 50 }}
                        to={{ opacity: 1, y: 0 }}
                      >
                        <div className="col-lg-8 text-center">
                          {
                            // TODO: Video Player
                          }
                          <img
                            src={videoPlaceholder}
                            alt="Video Placeholder"
                            className="img-fluid rounded"
                          />
                        </div>
                      </Tween>
                      <Tween duration={2} />
                    </Timeline>
                  </div>
                </div>
              </section>
            )}
          </Scene>
          <Scene duration="100%" triggerHook="onEnter">
            {progress => (
              <section className={`${styles.product_section} w-100`}>
                <div className="row no-gutters">
                  <div className="col-lg-auto">
                    <img
                      src={productVideoPlaceholder}
                      alt="Product Video"
                      className={styles.product_plaque_image}
                    />
                  </div>
                  <div className={`col-lg text-white ${styles.product_plaque}`}>
                    <Timeline
                      paused
                      totalProgress={progress}
                      wrapper={
                        <div className="d-flex h-100 flex-column text-center align-items-center justify-content-center" />
                      }
                    >
                      <Tween
                        staggerFrom={{ opacity: 0 }}
                        staggerTo={{ opacity: 1 }}
                        stagger={0.1}
                      >
                        <h2 className={styles.title}>The Zen Shirt</h2>
                        <p className={styles.description}>
                          This shirt is available in 5 sizes, and 3 more sizes
                          are up for pre-ordering. Find your right size now!
                        </p>
                        <div className="mt-1">
                          <a
                            className="btn btn-outline-light mr-3 btn-ucase"
                            href="/find-size"
                          >
                            Find my size
                          </a>
                          <a
                            className="btn btn-primary btn-ucase btn-glow"
                            href="/buy"
                          >
                            Buy Now
                          </a>
                        </div>
                      </Tween>
                      <Tween duration={1} />
                    </Timeline>
                  </div>
                </div>
              </section>
            )}
          </Scene>
        </Controller>
      </div>
    );
  }
}

export default PPZen;
