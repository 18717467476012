import React from "react";
import styles from "./Shibui.module.scss";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { useMediaLayout } from "use-media";
import videoPlaceholder from "../../assets/product-pages/shibui/video_placeholder.png";
import productVideoPlaceholder from "../../assets/product-pages/shibui/product_video_placeholder.png";
import logoCircle from "../../assets/product-pages/shibui/logo_circle.png";
import shibuiLogo from "../../assets/product-pages/shibui/shibui_logo.svg";
import featureImage1 from "../../assets/product-pages/shibui/feature_img_1.png";
import featureImage2 from "../../assets/product-pages/shibui/feature_img_2.png";
import featureImage3 from "../../assets/product-pages/shibui/feature_img_3.png";

export function PPShibui() {
  const isDesktop = useMediaLayout({ minWidth: 992 }, true);

  return (
    <div className={`${styles.page_bg} row`}>
      <Controller>
        <Scene duration="100%" pin triggerHook="onLeave">
          {progress => (
            <section
              className={`${styles.pp_section} ${
                styles.section1
              } overflow-hidden`}
            >
              <Timeline
                paused
                duration={1}
                totalProgress={progress}
                target={<div className={styles.section1_bg} />}
              >
                <Tween position={2} from={{ scale: 1 }} to={{ scale: 1.1 }} />
              </Timeline>

              <Timeline
                wrapper={<div className={styles.logo_lhs} />}
                paused
                duration={0.5}
                totalProgress={progress}
              >
                <Tween
                  position={1}
                  from={{ yPercent: 0, opacity: 1 }}
                  to={{ yPercent: -10, opacity: 0 }}
                >
                  <img src={logoCircle} alt="" />
                </Tween>
                <Tween
                  position={1}
                  from={{ yPercent: 0, opacity: 1 }}
                  to={{ yPercent: -40, opacity: 0 }}
                >
                  <img src={shibuiLogo} alt="" className={styles.shibuiLogo} />
                </Tween>
              </Timeline>
            </section>
          )}
        </Scene>
        <Scene
          duration="100%"
          pin
          triggerHook="onLeave"
          pinSettings={{ pushFollowers: false }}
        >
          {progress => (
            <section
              className={`${styles.pp_section} ${
                styles.section2
              } overflow-hidden`}
            >
              <Timeline
                paused
                totalProgress={progress}
                target={<div className={styles.section2_bg} />}
              >
                <Tween position={1} to={{ scale: 1 }} from={{ scale: 1.1 }} />
              </Timeline>

              <Timeline
                wrapper={<div className={styles.rhs} />}
                paused
                totalProgress={progress}
              >
                <Tween
                  to={{ yPercent: -20, opacity: 0 }}
                  from={{ yPercent: 0, opacity: 1 }}
                >
                  <h5>
                    The Shibui was made to make your life easier — comfort and
                    coverage!
                  </h5>
                  <p>
                    The added elastane in the fabric ensures a figure-flattering
                    silhouette and the desired spunk for those important
                    presentations and also date-night post work.
                  </p>
                </Tween>
              </Timeline>
            </section>
          )}
        </Scene>
        <Scene duration="100%" triggerHook="onEnter">
          {progress => (
            <section className={`${styles.pp_section} ${styles.section3}`}>
              <div className={styles.section3_parallax_bg} />
              <div className={styles.features_container}>
                <div className={styles.feature_box}>
                  <img src={featureImage1} alt="" />
                  <p>Cozy & Comfortable, made with Elastane</p>
                </div>
                <div className={styles.feature_box}>
                  <img src={featureImage2} alt="" />
                  <p>Perfect arm length offering an ideal coverage</p>
                </div>
                <div className={styles.feature_box}>
                  <img src={featureImage3} alt="" />
                  <p>
                    Figure flattering profile — Go on, take it out for drinks!
                  </p>
                </div>
              </div>
            </section>
          )}
        </Scene>
        <Scene duration="200%" pin={isDesktop} triggerHook="onLeave">
          {progress => (
            <section className={`${styles.pp_section} ${styles.section4}`}>
              <div className="d-flex flex-column h-100 align-items-stretch justify-content-center">
                <div className={styles.titleContainer}>
                  <div className="row">
                    <Timeline paused totalProgress={progress} duration={1}>
                      <Tween>
                        <div className="col-12 text-left">
                          <h3>Videos</h3>
                        </div>
                      </Tween>
                    </Timeline>
                  </div>
                </div>
                <div className={styles.videos_container}>
                  <Timeline paused totalProgress={progress} duration={2}>
                    <Tween />
                    <Tween
                      from={{ xPercent: 0 }}
                      to={{ xPercent: -50 }}
                      duration={1.5}
                    >
                      <div className={styles.videos}>
                        <div className={styles.video_box}>
                          <img src={videoPlaceholder} alt="" />
                          <p>
                            Figure flattering profile — Go on, take it out for
                            drinks!
                          </p>
                        </div>
                        <div className={styles.video_box}>
                          <img src={videoPlaceholder} alt="" />
                          <p>
                            Figure flattering profile — Go on, take it out for
                            drinks!
                          </p>
                        </div>
                        <div className={styles.video_box}>
                          <img src={videoPlaceholder} alt="" />
                          <p>
                            Figure flattering profile — Go on, take it out for
                            drinks!
                          </p>
                        </div>
                      </div>
                    </Tween>
                    <Tween />
                  </Timeline>
                </div>
              </div>
            </section>
          )}
        </Scene>
        <Scene duration="100%" triggerHook="onEnter">
          {progress => (
            <section className={`${styles.product_section} w-100`}>
              <div className="row no-gutters">
                <div className="col-lg-auto">
                  <img
                    src={productVideoPlaceholder}
                    alt="Product Video"
                    className={styles.product_plaque_image}
                  />
                </div>
                <div className={`col-lg text-white ${styles.product_plaque}`}>
                  <Timeline
                    paused
                    totalProgress={progress}
                    wrapper={
                      <div className="d-flex h-100 flex-column text-center align-items-center justify-content-center" />
                    }
                  >
                    <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
                      <h2 className={styles.title}>The Shibui</h2>
                      <p className={styles.description}>
                        This shirt is available in 5 sizes, and 3 more sizes are
                        up for pre-ordering. Find your right size now!
                      </p>
                      <div className="mt-1">
                        <a
                          className="btn btn-outline-light mr-3 btn-ucase"
                          href="/find-size"
                        >
                          Find my size
                        </a>
                        <a
                          className="btn btn-primary btn-ucase btn-glow"
                          href="/buy"
                        >
                          Buy Now
                        </a>
                      </div>
                    </Tween>
                    <Tween duration={1} />
                  </Timeline>
                </div>
              </div>
            </section>
          )}
        </Scene>
      </Controller>
    </div>
  );
}

export default PPShibui;
